import React from 'react'
import { createSvgIcon } from '@mui/material'

const CloseIc = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.49 328.74">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M308.61,0c1.44.37,2.88.7,4.31,1.11a21.19,21.19,0,0,1,10.67,34,45.39,45.39,0,0,1-3.3,3.5q-61.57,61.59-123.18,123.15a28,28,0,0,1-3.62,2.54c1.79,1.5,2.85,2.25,3.76,3.16q61.94,61.9,123.87,123.82c5.77,5.77,8.58,12.43,6.85,20.6a21.16,21.16,0,0,1-33.73,12.42,39.84,39.84,0,0,1-3.78-3.46q-61.6-61.57-123.16-123.18a38,38,0,0,1-2.65-3.26c-1.33,1.27-2.24,2.11-3.12,3q-62,62-124,124.1c-6.43,6.45-13.91,8.68-22.51,6.24-6.91-2-11.5-6.74-13.86-13.58-.35-1-.74-2-1.11-2.94v-7.06c1.45-6.43,5.54-11.07,10.07-15.59Q70.81,228,131.42,167.3a27.77,27.77,0,0,1,3.76-2.57c-1.92-1.53-3-2.23-3.92-3.13q-60.55-60.51-121.12-121C5.61,36.06,1.54,31.41,0,25V18C1.72,12,4.51,6.63,10.13,3.56c3-1.64,6.49-2.4,9.76-3.56h2.57C30.27,1,35.67,5.72,41,11.06q60.21,60.46,120.64,120.71c.81.82,1.67,1.59,3.25,3.1a23.63,23.63,0,0,1,2.27-3.29q60.2-60.27,120.38-120.52C292.84,5.72,298.23.94,306.05,0Z" />
            </g>
        </g>
    </svg>,
    'CloseIc',
)

export default CloseIc
