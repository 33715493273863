import React, { Suspense } from 'react'
import { AxiosInterceptorContext, DappProvider } from '@multiversx/sdk-dapp/wrappers'
import { NotificationModal, SignTransactionsModals, TransactionsToastList } from '@multiversx/sdk-dapp/UI'
import { RouterProvider } from 'react-router-dom'
import router from 'config/routes'
import { CssBaseline } from '@mui/material'
import { API_TIMEOUT, ENVIROMENT, WALLET_CONNECT_PROJECT_V2 } from 'config'

function App() {
    return (
        <AxiosInterceptorContext.Provider>
            <AxiosInterceptorContext.Interceptor authenticatedDomanis={[]}>
                <DappProvider
                    environment={ENVIROMENT}
                    dappConfig={{
                        shouldUseWebViewProvider: true,
                    }}
                    customNetworkConfig={{
                        name: 'customConfig',
                        apiTimeout: API_TIMEOUT,
                        walletConnectV2ProjectId: WALLET_CONNECT_PROJECT_V2,
                    }}
                >
                    <TransactionsToastList />
                    <NotificationModal />
                    <SignTransactionsModals />
                    <AxiosInterceptorContext.Listener />
                    <CssBaseline />
                    <Suspense>
                        <RouterProvider router={router} />
                    </Suspense>
                </DappProvider>
            </AxiosInterceptorContext.Interceptor>
        </AxiosInterceptorContext.Provider>
    )
}

export default App
