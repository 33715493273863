import React, { FC, lazy } from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { Home } from 'shared/icons'

const DashboardPage = lazy(async () => await import('pages/Dashboard'))
const LoginPage = lazy(async () => await import('pages/Login'))

type MUFRouteObject = RouteObject & {
    icon: FC
    name: string
    toShowInSideBar?: boolean
}

const routes: MUFRouteObject[] = [
    {
        path: '/',
        element: <DashboardPage />,
        icon: Home,
        name: 'Dashboard',
        toShowInSideBar: true,
    },
    {
        path: '/login',
        element: <LoginPage />,
        icon: Home,
        name: 'Login',
    },
]

const router = createBrowserRouter(routes)

export { routes }

export default router
