import React from 'react'
import { createSvgIcon } from '@mui/material'

const LogInIc = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 307.98 295.97">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M267.85,256V40h-4.17c-27.74,0-55.48.06-83.22,0-14.64-.05-24.35-14-19-27.27C164.63,4.93,170.55.23,179,.18c31.86-.18,63.73-.32,95.6.09C292,.49,307,15.93,307.83,33.36c.08,1.62.12,3.24.12,4.87q0,109.85,0,219.69c0,15.75-6.45,27.65-20.7,34.42a39,39,0,0,1-15.9,3.42c-30.23.32-60.48.21-90.72.13-11.93,0-20.72-8.75-20.64-20.1S169,256,181,256q41.24-.06,82.47,0Z" />
                <path d="M142.51,168h-4.32q-58.09,0-116.2,0c-7.61,0-14-2.38-18.44-8.79a19.87,19.87,0,0,1,13.18-30.92,41.86,41.86,0,0,1,6.34-.42q57.53,0,115.07,0h5.21c-1.45-1.6-2.28-2.61-3.2-3.53-9.27-9.28-18.65-18.46-27.81-27.85-11-11.31-6.54-29.4,8.33-33.58,7.12-2,13.82-.64,19.12,4.61q33.53,33.25,66.77,66.81c7.88,8,7.25,20-.95,28.27q-26,26.19-52.2,52.24c-4,4-7.94,8-11.93,11.92-8.9,8.84-21.19,9.33-29.39,1.19s-7.92-20.41,1-29.35,17.83-17.86,26.79-26.76a32.65,32.65,0,0,1,3.26-2.54Z" />
            </g>
        </g>
    </svg>,
    'LogInIc',
)

export default LogInIc
