import React from 'react'
import { createSvgIcon } from '@mui/material'

const FrameIt = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Calque_1"
        data-name="Calque 1"
        viewBox="0 0 760.26 972.34"
        width="760.26"
        height="972.34"
    >
        <polygon points="254.58 0 254.58 109.31 109.31 109.31 109.31 254.47 0 254.47 0 0 254.58 0" />
        <polygon points="760.26 717.87 760.26 972.34 505.79 972.34 505.79 863.03 650.95 863.03 650.95 717.87 760.26 717.87" />
        <rect x="156.72" y="146.8" width="145.16" height="145.27" />
        <path d="M156.63,292H49V931.59H458.41V823.92H156.63Z" />
        <path d="M603.69,678.7H711.37V39.17H301.91V146.82H603.69Z" />
    </svg>,
    'FrameIt',
)

export default FrameIt
