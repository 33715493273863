import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out'

export const ENVIROMENT = 'mainnet'
export const API_TIMEOUT = 10000
export const WALLET_CONNECT_PROJECT_V2 = 'c7abf993e449e768fa89064b77cc2230'
export const MULTIVERSX_API_URL = 'https://api.multiversx.com'
export const MULTIVERSX_GATEWAY_URL = 'https://gateway.multiversx.com'
export const REFERRAL_API_URL = 'https://referral-api.multi-fights.com'
export const NETWORK_PROVIDER = new ProxyNetworkProvider(MULTIVERSX_GATEWAY_URL)
export const OWNER_ADDRESS = 'erd1kwv75jn5nu0z0avcvplfpq0r9x28y8ym4ypf8w99jstqxaxslzms6qjjd2'
export const SMART_CONTRACT_ADDRESS = 'erd1qqqqqqqqqqqqqpgqhj86kyn4zuet9amjewpm9z6azv6yhuztlzmse5v46e'
