import React from 'react'
import { createSvgIcon } from '@mui/material'

const CheckIc = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.21 326.3">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M326.21,153.56v19.12c-.2,1.13-.43,2.25-.59,3.38-1,7-1.6,14.14-3.11,21.06C314,236.29,293.88,268.4,262,292.57c-39.69,30.07-84.39,40-133.13,29.88-39-8.14-70.74-28.59-95-60.13C7.64,228.26-3.26,189.48.84,146.8c3.32-34.59,16.75-65.12,39.79-91.19C65.89,27,97.36,9.23,135,2.54,141.12,1.45,147.34.83,153.53,0h19.11a16.33,16.33,0,0,0,2.46.52A155.31,155.31,0,0,1,246.27,23c42.26,25.9,68.27,63.24,77.4,112.09C324.81,141.17,325.37,147.39,326.21,153.56Zm-188,37c-6.6-6-12.52-11.48-18.48-16.9-9-8.22-17.81-16.73-27.19-24.53-8.73-7.26-22.24-2.49-24.88,8.38-1.69,7,.65,12.66,5.88,17.41q26.43,24,52.76,48c8.32,7.58,17,7.38,25-.62Q202,172.06,252.61,121.79c.91-.9,1.8-1.82,2.61-2.79a16.3,16.3,0,0,0-4.42-24.7c-7-4-14.54-2.72-21,3.78q-44.73,44.7-89.4,89.43A21.91,21.91,0,0,0,138.23,190.54Z" />
            </g>
        </g>
    </svg>,
    'CheckIc',
)

export default CheckIc
