import React from 'react'
import { createSvgIcon } from '@mui/material'

const CopyIc = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.09 320.15">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M319.91,54.37C319.67,24.56,296,.5,266.37.27q-74.43-.58-148.86,0C92.46.43,70.78,18.61,65.59,43.16c-1.41,6.64-1.43,13.58-2.13,20.9-2.88,0-5.86,0-8.85,0C25.12,64.42.54,88.09.3,117.54Q-.3,192,.29,266.4A54,54,0,0,0,53.7,319.84q74.43.6,148.86,0c21.75-.22,37.57-11.47,47.44-30.69,2.69-5.24,4.07-11.15,6.34-17.58,5.06,0,10.78.73,16.25-.12,28.09-4.34,47.16-26.66,47.41-55,.1-11.75,0-23.5,0-35.25C320,138.86,320.24,96.61,319.91,54.37ZM288.09,216.28c0,13.8-9.93,23.46-23.7,23.82-2.6.07-5.2,0-8.31,0v-5c0-38.48.06-77,0-115.46a55,55,0,0,0-44.25-54.41,60.7,60.7,0,0,0-12.27-1.11c-33-.09-66,0-99,0H96c.22-4.84-.1-9.39.74-13.71,2.12-10.84,11.4-18.27,23-18.3q72.34-.16,144.7,0c13.75,0,23.67,10,23.7,23.78Q288.27,136.05,288.09,216.28Z" />
            </g>
        </g>
    </svg>,
    'CopyIc',
)

export default CopyIc
