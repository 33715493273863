import React from 'react'
import { createSvgIcon } from '@mui/material'

const Twitter = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="44" viewBox="0 0 54 44" fill="none">
        <path d="M16.9627 44C37.341 44 48.4886 27.0677 48.4886 12.4097C48.4886 11.9359 48.4886 11.4553 48.4684 10.9815C50.6387 9.40595 52.5118 7.45538 54 5.22114C51.9727 6.11751 49.8243 6.70828 47.6246 6.97428C49.9417 5.58544 51.6771 3.40021 52.5082 0.824764C50.3313 2.11804 47.9487 3.02591 45.4646 3.50862C43.7946 1.7251 41.5847 0.543577 39.1773 0.147056C36.7698 -0.249465 34.2992 0.161149 32.148 1.31529C29.9968 2.46944 28.2852 4.30271 27.2783 6.53112C26.2713 8.75954 26.0252 11.2587 26.5781 13.6416C22.1729 13.4201 17.8633 12.2725 13.9288 10.2732C9.99428 8.27396 6.52281 5.46768 3.7395 2.03639C2.32652 4.48362 1.89531 7.37839 2.53343 10.1329C3.17155 12.8873 4.83116 15.295 7.17525 16.867C5.4187 16.8071 3.70076 16.3341 2.16 15.4861V15.6384C2.16303 18.2021 3.04866 20.6861 4.6672 22.6705C6.28574 24.6549 8.5379 26.0181 11.043 26.5295C10.0921 26.7922 9.1099 26.9232 8.12362 26.9188C7.42832 26.9209 6.73441 26.8563 6.05137 26.7258C6.75941 28.9327 8.13805 30.8622 9.99421 32.2441C11.8504 33.626 14.0911 34.3911 16.4025 34.4322C12.4759 37.5249 7.62548 39.2022 2.6325 39.1941C1.75271 39.1978 0.873528 39.147 0 39.0418C5.06761 42.2816 10.9529 44.0019 16.9627 44Z" />
    </svg>,
    'Twitter',
)

export default Twitter
